import { EnvironmentShape } from "./environment.schema";

export const environment: EnvironmentShape = {
  production: false,
  launchdarklyClientId: '63ffdda3f928441354728819',
  ldRelayProxyUrl: 'https://teacher-launch-darkly.dev.renaissance.com',
  graphqlUrl: 'https://teach.dev.renaissance.com/v1/graphql',
  cloudGraphqlUrl: 'https://teacher-api.dev.renaissance.com/v1/graphql',
  expiredJwtRedirectUrl: 'https://schools.renaissance.com/',
  systemStatusRedirectUrl: "https://status.renaissance.com/",
  starSkillsUrl: 'https://global-dv.renaissance-golabs.com/teacherjourneyadapter/Skills/',
  appNavUrl: 'https://global-dv.renaissance-golabs.com/teacherjourneyadapter/AppNav',
  searchLambdaUrl: 'https://teacher-search-api.dev.renaissance.com/search',
  headerConfig: {
    url: 'https://resources-zone99.renaissance-go.com/scripts/renlearn/customelements/renaissanceheader',
    version: 'RenaissanceHeader-CE/RenaissanceHeader-CE-15.0.112.zip'
  },
  rgpResourceSiteUrl: 'https://resources-devel.renaissance-golabs.com',
  helpRedirectBaseUrl: 'https://help2redirect.renaissance.com/redir.asp?c=',
  chatConfigScriptUrl: 'https://chatfiledev.renlearn.com/whoson_realtime.js',
  sessionKeepAliveUrl: 'https://global-dv.renaissance-golabs.com/teacherjourneyadapter/Session/KeepAlive',
  apiCacheTtl: 42300,
  superstarUrl: 'https://superstars-api.dev.renaissance.com',
  launchControlServiceUrl: 'https://global-dv.renaissance-golabs.com/launchcontrol/launch/',
  appNavV2Url: 'https://global-dv.renaissance-golabs.com/teacherjourneyadapter/AppNavV2',
  rgpTeacherPreferencesUrl: 'https://global-dv.renaissance-golabs.com/teacherjourneyadapter/NextPreference/TeacherPreferences',
  ltiServiceIntegrationUrl: 'https://teacher-lti.dev.renaissance.com/api/lti/savvas',
  openSearchUrl: 'https://search.dev.renaissance.com/api/v1/search',
  newRelicOptions: {
    init: {
      distributed_tracing: {
        enabled:true,
        cors_use_newrelic_header:true,
        cors_use_tracecontext_headers:true,
        allowed_origins: ['https://teacher.dev.renaissance.com']
      },
      privacy: {
        cookies_enabled:true
      },
      ajax: {
        deny_list: [
            "bam.nr-data.net",
            "global-devel.renaissance-golabs.com/identityservice/sso/pingv2",
            "global-devel.renaissance-golabs.com/identityservice/sso/ping",
            "global-prestg.renaissance-golabs.com/identityservice/sso/pingv2",
            "global-prestg.renaissance-golabs.com/identityservice/sso/ping"
        ]
      }
    },
    info: {
      beacon:"bam.nr-data.net",
      errorBeacon:"bam.nr-data.net",
      licenseKey:"NRJS-236ccbbafe02047f975",
      applicationID:"1120345514",
      sa:1
    },
    loader_config: {
      accountID:"4554724",
      trustKey:"2940311",
      agentID:"1120345514",
      licenseKey:"NRJS-236ccbbafe02047f975",
      applicationID:"1120345514"
    }
  }
};
