import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";
import { environment } from "src/environments/environment";

const options = environment.newRelicOptions;
try {
  if (shouldLoadNewRelic()) {
    new BrowserAgent(options);
  }
}
catch {
  console.error('New Relic initialization failed');
}

function shouldLoadNewRelic() {
  var regex = /Version\/(\d*).*\s/;
  // I know, browser sniffing is bad, but so is safari support in NR
  var hasSafariUserAgent = navigator.userAgent && navigator.userAgent.indexOf('Safari') > -1;
  var safariVersionMatch = navigator.userAgent.match(regex);
  var foundVersion = safariVersionMatch ? safariVersionMatch.length > 1 : false;

  if (hasSafariUserAgent && safariVersionMatch && foundVersion) {
    var numericVersion = Number(safariVersionMatch[1]);
    if (numericVersion <= 12) {
      console.warn('Safari <= 12 detected not loading NR');
      return false;
    }
  }

  const urlParams = new URLSearchParams(window.location.search);
  const paramValue = urlParams.get('disableNewRelic');

  if (paramValue == 'true') {
    return false;
  }

  // Load New Relic if we fall through checks
  return true;
}
